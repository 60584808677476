<template>
  <div class="container">
    <div class="searchCont">
      <div class="search">
        <input
          type="text"
          placeholder="请输入姓名/手机查询"
          v-model="value"
          @blur="searchName"
          @key.enter="searchName"
        />
        <span @click="searchName" style="font-size:14px;color:#2f7dcd">搜索</span>
        <i class="el-icon-finished" @click="goSearch"></i>
        
      </div>
    </div>
    <section class="searchTags">
      <p class="total">人员总数：{{ this.list.length }}</p>
      <div class="searchBox">
        <span class="search-tag" v-for="item in search" v-if="item.type !='inChina'"
          >{{ item.name&&typeof item.name === "object" ? item.name.join(",") : item.name }}
          <!-- <i class="el-icon-close" @click="delSearch(item)"></i> -->
          </span>
      </div>
    </section>
    <ul>
      <li class="card" v-for="(item, index) in list" @click="goDetail(item.mobile)">
        <h5>
          <span>{{ item.name }}</span>
          <span>{{ item.mobile }}</span>
        </h5>
        <div class="middle">
          <span class="status_tag">{{
            item.nowStatus ? item.nowStatus : "正常"
          }}</span>
          <span
            class="tag"
            :class="'level' + item.inLevel"
            v-if="item.inLevel"
            >{{
              item.inLevel == 1
                ? "低风险地区"
                : item.inLevel == 2
                ? "中风险地区"
                : "高风险地区"
            }}</span
          >
          <span style="font-size: 12px; color: #999">{{
            item.type == 0 ? "管理人员" :item.type == 1? "管理人员":"务工人员"
          }}</span>
        </div>
        <p>{{item.institution||''}}{{item.country?'/'+item.country:""}}{{item.project?'/'+item.project:""}}</p>
        <div class="bot" v-if="item.aimAddress ">
          <span>
            <img src="@/assets/position.png" alt="" />
            {{ item.aimAddress }}
          </span>
          <!-- <span class="showIcon" @click="showTrip(item, index)">{{
            tripList[index] ? "收起行程" : "展开行程"
          }}</span> -->
        </div>
        <p style="color: #999; font-size: 13px">
          {{ item.tripAndTime && item.tripAndTime.substr(0, 10) }}
        </p>

        <section class="hisContent" v-if="tripList[index]">
          <el-timeline>
            <el-timeline-item
              v-for="(child, idx) in tripList[index]"
              :key="idx"
              :timestamp="child.goTime.substr(0, 10)"
            >
              <div class="tit">
                <span
                  >目的地：{{
                    (child.abroadName ? child.abroadName : "") +
                    child.aimAddress
                  }}</span
                >
              </div>
            </el-timeline-item>
          </el-timeline>
        </section>
      </li>
    </ul>
  </div>
</template>

<script>
import apicker from "@/components/common/picker";
import adate from "@/components/common/date";
import { tripLevelList, tripList } from "@/api/api";
import format from "@/mixins/time";
export default {
  mixins: [format],
  data() {
    return {
      total: 0,
      areas: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
      ],
      list: [],
      tripList: [],
      checked: false,
      search: this.$store.state.search,
      value: "",
    };
  },
  mounted() {
    let start = new Date().valueOf() - 7 * 24 * 3600 * 1000;
    let end = new Date().valueOf() + 24 * 3600 * 1000;
    let form = {
      start: this.nowTime(start),
      end: this.nowTime(end),
    };
    this.getList(form);
  },
  components: {
    apicker,
    adate,
  },
  watch: {
    "$store.state.search": {
      deep: true,
      handler: function (val) {
        this.search = val;
        this.getList();
        this.$forceUpdate();
      },
    },
  },
  methods: {
    searchName() {
      let form = {};
      if (isNaN(this.value)) {
        form.name = this.value;
      } else {
        form.mobile = this.value;
      }
      this.getList(form);
    },
    delSearch(item) {
      this.$store.commit("setSearch", item);
    },
    getList(obj) {
      let query = obj ? { ...obj } : "";
      let search = this.$store.state.search;
      search.forEach((item) => {
        if (typeof item.value === "object") {
          query[item.type] = item.value?item.value.join(","):'';
        } else {
          query[item.type] = item.value ? item.value : item.value!=0?"":0;
        }
      });
      if(!query.abroadName){
        delete query.inChina
      }
      tripLevelList(query).then((res) => {
        this.list = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    showTrip(obj, idx) {
      if (!this.tripList[idx]) {
        tripList({ mobile: obj.mobile }).then((res) => {
          this.$set(this.tripList, idx, res.data.data);
        });
      } else {
        this.$set(this.tripList, idx, null);
      }
    },
    handleChange(e) {
      this.query.inBeijing = e ? 2 : 0;
      this.getList();
    },
    handlePicker(e) {
      this.query.institution = e;
      this.getList();
    },
    handleDate(e) {
      this.query.startTime = e;
      this.query.endTime = e;
      this.getList();
    },
    goSearch(){
      this.$store.commit('clearSearch')
      this.$router.replace('/search')
      
    },
    goDetail(phone){
      this.$router.push('/person/detail?mobile='+encodeURIComponent(phone))
    }
  },
};
</script>

<style scoped>
.container {
  padding-top: 0.6rem;
}
.card {
  background: #fff;
  padding: 0.1rem 0.15rem 0.12rem;
  border-top: 1px solid #d9d9d9;
  position: relative;
}
.card h5 {
  font-size: 0.14rem;
  font-weight: normal;
}
.card h5 span {
  margin-right: 0.1rem;
}
.card > p {
  font-size: 0.15rem;
  margin: 0.04rem 0;
}
.bot {
  display: flex;
}
.bot img {
  width: 0.16rem;
}
.bot {
  display: flex;
  justify-content: space-between;
  margin-top: 0.05rem;
}
.bot span {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  color: #040404;
}
.bot span:nth-child(2) {
  color: #2f7dcd;
  justify-content: center;
}
.status {
  position: absolute;
  top: 50%;
  right: 0.2rem;
  background: rgba(79, 119, 170, 0.19);
  width: 0.56rem;
  height: 0.28rem;
  line-height: 0.28rem;
  color: #021412;
  font-size: 0.14rem;
  border-radius: 10px;
  text-align: center;
}
.searchCont {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #fff;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.05rem;
}
.search {
  background: #fff;
  padding: 0.14rem 0.13rem;
  display: flex;
}
.search-item {
  flex: 1;
  font-size: 0.15rem;
  padding-right: 0rem;
  position: relative;
  text-align: center;
}
.search-item:not(:last-child):after {
  content: "";
  height: 0.15rem;
  width: 0px;
  border-right: 1px solid rgba(112, 112, 112, 0.6);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.showIcon {
  flex: 0.3 !important;
}
.hisContent {
  padding: 0.2rem 0;
}
.tag {
  display: inline-block;
  padding: 1px 7px;
  border-radius: 0.09rem;
  font-size: 12px;
  line-height: 0.18rem;
  color: #fff;
}
.level2 {
  background: #e8a270;
}
.level1 {
  background: #878786;
}
.level3 {
  background: #e36f6b;
}
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search input {
  width: 2.6rem;
  height: 0.32rem;
  line-height: 0.32rem;
  border-radius: 0.16rem;
  outline: none;
  border: 1px solid #ccc;
  padding: 0 0.19rem;
  font-size: 0.14rem;
}
.search i {
  color: #2f7dcd;
  font-size: 0.23rem;
}
.total {
  margin: 0.05rem 0 0;
  padding: 0 0.14rem;
  color: #1f1f1f;
  font-size: 0.15rem;
  font-weight: bold;
}
.searchBox {
  width: 100%;
  padding: 0 0.1rem;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
}
.search-tag {
  min-width: 0.7rem;
  height: 0.3rem;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  line-height: 0.3rem;
  font-size: 0.14rem;
  padding: 0 7px;
  background: #dae1eb;
  color: #2f7dcd;
  margin: 5px;
}
.searchTags {
  padding: 0.1rem 0;
}
.middle span {
  margin-right: 0.1rem;
}
.status_tag {
  background: rgba(79, 119, 170, 0.19);
  line-height: 0.18rem;
  color: #2f7dcd;
  font-size: 12px;
  border-radius: 0.09rem;
  padding: 3px 7px;
}
</style>